<template>
  <b-modal id="travauxModal" ref="travauxModal" @hide="close">
    <template #modal-header>
      <h5>{{ $t("NEW") }} {{ $t("WORKS") }}</h5>
      <b-button size="sm" @click="resetModal()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17.028"
          height="17.028"
          viewBox="0 0 17.028 17.028"
        >
          <path
            id="Icon_material-close"
            data-name="Icon material-close"
            d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
            transform="translate(-7.5 -7.5)"
            fill="#393939"
          />
        </svg>
      </b-button>
    </template>

    <form @submit.prevent="addCAT">
      <div class="center">
        <b-form-group :label="$t('WORDING') + '*'" label-for="lib">
          <b-form-input
            id="lib"
            v-model="v$.newTRA.lib.$model"
            :state="validateState('lib')"
            oninput="this.value = this.value.toUpperCase()"
            aria-describedby="lib-feedback"
          ></b-form-input>
          <error-handle
            :list="v$.newTRA.lib.$errors"
            id="lib-feedback"
          ></error-handle>
          <div v-if="erreurlist.lib" class="error-message">
            <ul v-if="Array.isArray(erreurlist.lib)">
              <span v-for="(e, index) in erreurlist.lib" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.lib }}</span>
          </div>
        </b-form-group>
        <b-form-group :label="$t('GALLERY')">
          <multiselect
            v-model="newTRA.galerie"
            :placeholder="$t('CHOOSE_IMAGE')"
            label="titre"
            track-by="titre"
            :options="getAllgallery"
            :option-height="72"
            :custom-label="customLabel"
            :show-labels="false"
          >
            <template slot="singleLabel" slot-scope="props">
              <div class="option">
                <span class="option__desc">
                  <span class="option__title">
                    {{ props.option.titre }}
                  </span>
                </span>
              </div>
            </template>
            <template slot="option" slot-scope="props">
              <div class="option">
                <img
                  class="option__image mr-4"
                  :src="`${galleryUrl}${props.option.image}`"
                  alt="None"
                />
                <span class="option__desc">
                  <span class="option__title">
                    {{ props.option.titre }}
                  </span>
                </span>
              </div>
            </template>
            <template slot="noResult">
              {{ $t("NO_DATA_FOUND") }}
            </template>
          </multiselect>
          <div v-if="erreurlist.gallery_id" class="error-message">
            <ul v-if="Array.isArray(erreurlist.gallery_id)">
              <span v-for="(e, index) in erreurlist.gallery_id" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.gallery_id }}</span>
          </div>
        </b-form-group>
        <b-form-group :label="$t('COMPANY_ID')" v-if="isSuperAdmin">
          <multiselect
            v-model="newTRA.entreprise"
            :searchable="true"
            :close-on-select="true"
            :multiple="false"
            :options="computedAllentreprises"
            label="lib"
            track-by="id"
          >
            <template slot="noResult">
              {{ $t("NO_DATA_FOUND") }}
            </template>
          </multiselect>
          <div v-if="erreurlist.entreprise_id" class="error-message">
            <ul v-if="Array.isArray(erreurlist.entreprise_id)">
              <span v-for="(e, index) in erreurlist.entreprise_id" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.entreprise_id }}</span>
          </div>
        </b-form-group>

        <b-form-group label="Favoris" label-for="Favoris">
          <b-form-checkbox
            id="Favoris"
            v-model="newTRA.favoris"
            name="favoris"
            value="true"
            unchecked-value="false"
          >
            favoris
          </b-form-checkbox>
        </b-form-group>
      </div>
    </form>

    <template #modal-footer>
      <b-alert variant="warning" show v-if="errorS">
        {{ errorS }}
      </b-alert>
      <div class="double">
        <b-button variant="danger" @click="resetModal()">
          <div class="block-spinner">
            {{ $t("CANCEL") }}
          </div>
        </b-button>
        <b-button
          variant="success"
          @click="addCAT"
          class="succes-btn-modal btn-success"
        >
          <div class="block-spinner">
            {{ $t("SAVE") }}
            <div v-if="getLoadingtravaux" class="loading ml-2">
              <div class="spinner-border" role="status"></div>
            </div>
          </div>
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import { domains } from "@/environment";
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength } from "@vuelidate/validators";
import errorHandle from "../ui/errorHandle.vue";

export default {
  components: {
    errorHandle,
  },
  setup() {
    return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
  },
  data() {
    return {
      galleryUrl: domains.gallery,
      newTRA: {
        lib: null,
        favoris: null,
        lang: "fr",
        galerie: null,
      },
      erreurlist: {
        lib: null,
        entreprise_id: null,
        gallery_id: null,
      },
      page: 1,
      perPage: 1000,
      errorS: false,
      box: "",
    };
  },
  validations() {
    return {
      newTRA: {
        lib: { required, maxLength: maxLength(100) },
      },
    };
  },
  methods: {
    ...mapActions(["store_travaux", "allgallery", "all_entreprises"]),

    validateState(name) {
      const { $dirty, $error } = this.v$.newTRA[name];
      return $dirty ? !$error : null;
    },

    customLabel({ title }) {
      return `${title}`;
    },
    async addCAT() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;

      this.erreurlist = {
        lib: null,
        entreprise_id: null,
        gallery_id: null,
      };
      this.errorS = false;
      const data = {
        lib: this.newTRA.lib,
        gallery_id: this.newTRA.galerie ? this.newTRA.galerie.id : null,
        favoris: this.newTRA.favoris === null ? "false" : "true",
        lang: "fr",
      };
      if (this.newTRA.entreprise && this.newTRA.entreprise.id) {
        data.entreprise_id = this.newTRA.entreprise.id;
      }
      await this.store_travaux(data)
        .then(() => {
          this.resetModal();
          this.$emit("updateList");
        })
        .catch((err) => {
          if (this.isObject(err)) {
            for (const [key, value] of Object.entries(err)) {
              if (!this.erreurlist[key]) {
                this.erreurlist[key] = value;
              }
            }
          } else {
            this.errorS = err;
          }
        });
    },
    isObject(obj) {
      return Object.prototype.toString.call(obj) === "[object Object]";
    },
    resetModal() {
      this.errorS = null;
      this.$refs["travauxModal"].hide();
      this.erreurlist = {
        lib: null,
        entreprise_id: null,
        gallery_id: null,
      };
      this.newTRA = {
        lib: null,
        galerie: null,
        favoris: null,
        lang: "fr",
      };
      this.$nextTick(() => {
        this.v$.$reset();
      });
    },

    close(bv) {
      if (this.$refs["travauxModal"].isShow) {
        if (bv.trigger == "backdrop") {
          bv.preventDefault();
          this.showMsgBox();
        }
      }
    },

    showMsgBox() {
      this.box = "";
      this.$bvModal
        .msgBoxConfirm(`${this.$t("CLOSE")}`, {
          title: `${this.$t("CONFIRM")}`,
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          cancelVariant: "danger",
          okTitle: `${this.$t("YES")}`,
          cancelTitle: `${this.$t("NO")}`,
          footerClass: "p-2",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) {
            this.resetModal();
          }
        });
    },
  },
  mounted() {
    if (!this.getAllgallery) {
      this.allgallery({ page: this.page, per_page: this.perPage });
    }
    if (this.isSuperAdmin)
      this.all_entreprises({
        page: this.page,
        per_page: this.perPage,
        search: this.search,
        with_default: 1,
      });
  },
  computed: {
    ...mapGetters([
      "getAllgallery",
      "getOnlineUser",
      "getAllentreprises",
      "getLoadingtravaux",
    ]),
    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    computedAllentreprises() {
      var list = [
        {
          lib: "TOUTES LES ENTREPRISES",
          id: 1,
        },
      ];
      if (this.getAllentreprises)
        this.getAllentreprises.forEach((element) => {
          if (element.id != 1) list.push(element);
        });
      return list;
    },
  },
};
</script>
<style lang="scss" scoped>
.option {
  display: flex;
  align-items: center;
  .option__image {
    background-color: #465081;
    padding: 4px;
    max-height: 42px;
    max-width: 42px;
  }
}
</style>
