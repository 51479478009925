<template>
  <b-modal id="CategorieModal" ref="CategorieModal" hide-footer @hide="close">
    <template #modal-header>
      <h5>{{ $t("NEW") }} {{ $t("CATEGORIES") }}</h5>
      <b-button size="sm" @click="resetModal()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17.028"
          height="17.028"
          viewBox="0 0 17.028 17.028"
        >
          <path
            id="Icon_material-close"
            data-name="Icon material-close"
            d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
            transform="translate(-7.5 -7.5)"
            fill="#393939"
          />
        </svg>
      </b-button>
    </template>

    <form @submit.prevent="addPRE">
      <div class="center">
        <b-form-group :label="$t('WORDING')">
          <b-form-input
            v-model="newCategorie.lib"
            required
            maxLength="50"
          ></b-form-input>
          <div v-if="erreurlist.lib" class="error-message">
            <ul v-if="Array.isArray(erreurlist.lib)">
              <span v-for="(e, index) in erreurlist.lib" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.lib }}</span>
          </div>
        </b-form-group>

        <b-form-group :label="$t('CATMETIER')">
          <multiselect
            v-model="newCategorie.categorie_metier"
            :searchable="true"
            :close-on-select="true"
            :multiple="true"
            :options="getAllcategorieMetier"
            label="lib"
            track-by="id"
          >
            <template slot="noResult">
              {{ $t("NO_DATA_FOUND") }}
            </template>
          </multiselect>
          <div v-if="erreurlist.categorie_metier" class="error-message">
            <ul v-if="Array.isArray(erreurlist.categorie_metier)">
              <span
                v-for="(e, index) in erreurlist.categorie_metier"
                :key="index"
              >
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.categorie_metier }}</span>
          </div>
        </b-form-group>
      </div>

      <div class="double">
        <b-alert variant="warning" show v-if="errorS" class="messageError">
          {{ errorS }}
        </b-alert>
        <b-button variant="danger" @click="resetModal()">
          <div class="block-spinner">
            {{ $t("CANCEL") }}
          </div>
        </b-button>
        <b-button
          variant="success"
          type="submit"
          class="succes-btn-modal btn-success"
        >
          <div class="block-spinner">
            {{ $t("SAVE") }}
            <div v-if="getLoadingcategorieArticle" class="loading ml-2">
              <div class="spinner-border" role="status"></div>
            </div>
          </div>
        </b-button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      newCategorie: {
        lib: null,
        categorie_metier: null,
      },
      erreurlist: {
        lib: null,
        categorie_metier: null,
      },
      errorS: null,
      box: "",
    };
  },
  methods: {
    ...mapActions(["store_categorieArticle", "allcategorieMetier"]),

    async addPRE() {
      this.errorS = null;
      this.erreurlist = {
        lib: null,
        categorie_metier: null,
      };
      var data = {
        lib: this.newCategorie.lib,
        categorie_metier_id: this.newCategorie.categorie_metier.map(
          (item) => item.id
        ),
      };
      await this.store_categorieArticle(data)
        .then(() => {
          this.resetModal();
          this.$emit("updateList");
        })
        .catch((err) => {
          if (this.isObject(err)) {
            for (const [key, value] of Object.entries(err)) {
              if (!this.erreurlist[key]) {
                this.erreurlist[key] = value;
              }
            }
          } else {
            this.errorS = err;
          }
        });
    },
    isObject(obj) {
      return Object.prototype.toString.call(obj) === "[object Object]";
    },
    resetModal() {
      this.errorS = null;
      this.$refs["CategorieModal"].hide();
      this.newCategorie = {
        lib: null,
        categorie_metier: null,
      };
      this.erreurlist = {
        lib: null,
        categorie_metier: null,
      };
    },

    close(bv) {
      if (this.$refs["CategorieModal"].isShow) {
        if (bv.trigger == "backdrop") {
          bv.preventDefault();
          this.showMsgBox();
        }
      }
    },

    showMsgBox() {
      this.box = "";
      this.$bvModal
        .msgBoxConfirm(`${this.$t("CLOSE")}`, {
          title: `${this.$t("CONFIRM")}`,
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          cancelVariant: "danger",
          okTitle: `${this.$t("YES")}`,
          cancelTitle: `${this.$t("NO")}`,
          footerClass: "p-2",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) {
            this.resetModal();
          }
        });
    },
  },
  computed: {
    ...mapGetters([
      "getAllcategorieMetier",
      "getOnlineUser",
      "getLoadingcategorieArticle",
    ]),
    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    computedAllentreprises() {
      var list = [
        {
          lib: "TOUTES LES ENTREPRISES",
          id: 1,
        },
      ];
      if (this.getAllentreprises)
        this.getAllentreprises.forEach((element) => {
          if (element.id != 1) list.push(element);
        });
      return list;
    },
  },
  mounted() {
    this.allcategorieMetier({ page: 1, per_page: 1000 });
  },
};
</script>

<style lang="scss" scoped>
.actionModel {
  position: relative;
  padding: 52px 0 0;

  .messageError {
    position: absolute;
    top: 5px;
    bottom: 44px;
    color: #e4261b;
    margin: 0;
    font-size: 15px;
    background-color: #fff;
    border-color: #fff;
  }
}
</style>
