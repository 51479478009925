<template>
  <b-modal id="forfaitModal" ref="forfaitModal" scrollable @hide="close">
    <template #modal-header>
      <h5>{{ $t("NEW") }} {{ $t("FORFAIT") }}</h5>
      <b-button size="sm" @click="resetModal()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17.028"
          height="17.028"
          viewBox="0 0 17.028 17.028"
        >
          <path
            id="Icon_material-close"
            data-name="Icon material-close"
            d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
            transform="translate(-7.5 -7.5)"
            fill="#393939"
          />
        </svg>
      </b-button>
    </template>

    <form @submit.prevent="addPRO">
      <div class="center">
        <b-form-group :label="$t('WORDING') + '*'" label-for="name">
          <b-form-input
            id="lib"
            v-model="v$.newforfait.lib.$model"
            :state="validateState('lib')"
            oninput="this.value = this.value.toUpperCase()"
            aria-describedby="lib-feedback"
          ></b-form-input>
          <error-handle
            :list="v$.newforfait.lib.$errors"
            id="lib-feedback"
          ></error-handle>
          <div v-if="erreurlist.lib" class="error-message">
            <ul v-if="Array.isArray(erreurlist.lib)">
              <span v-for="(e, index) in erreurlist.lib" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.lib }}</span>
          </div>
        </b-form-group>

        <b-form-group :label="$t('REFERENCE')" label-for="reference ">
          <b-form-input
            id="reference"
            oninput="this.value = this.value.toUpperCase()"
            v-model="v$.newforfait.ref.$model"
            :state="validateState('ref')"
            aria-describedby="ref-feedback"
          ></b-form-input>
          <error-handle
            :list="v$.newforfait.ref.$errors"
            id="ref-feedback"
          ></error-handle>
          <div v-if="erreurlist.ref" class="error-message">
            <ul v-if="Array.isArray(erreurlist.ref)">
              <span v-for="(e, index) in erreurlist.ref" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.ref }}</span>
          </div>
        </b-form-group>
        <b-form-group :label="$t('VAT') + '*'" label-for="tva">
          <b-form-select
            v-model="v$.newforfait.tva.$model"
            :options="optionsTVA"
            :state="validateState('tva')"
            aria-describedby="tva-feedback"
          ></b-form-select>
          <error-handle
            :list="v$.newforfait.tva.$errors"
            id="tva-feedback"
          ></error-handle>
          <div v-if="erreurlist.tva" class="error-message">
            <ul v-if="Array.isArray(erreurlist.tva)">
              <span v-for="(e, index) in erreurlist.tva" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.tva }}</span>
          </div>
        </b-form-group>
        <div class="modalforfait">
          <b-col md="4">
            <b-form-group
              style="margin-bottom: 0"
              :label="$t('TYPE_UNITE') + '*'"
              label-for="name"
            >
              <b-form-select
                v-model="v$.newforfait.type_unite.$model"
                :options="optionstype"
                :state="validateState('type_unite')"
                aria-describedby="type_unite-feedback"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group style="margin-bottom: 0" :label="$t('Quantité')">
              <b-form-input
                v-model="v$.newforfait.quantite.$model"
                Type="number"
                step="1"
                min="1"
                :state="validateState('quantite')"
                aria-describedby="quantite-feedback"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              style="margin-bottom: 0"
              :label="$t('UNIT_PRICE') + '*'"
              label-for="nameunité"
            >
              <b-form-input
                id="nameunité"
                v-model="v$.newforfait.unit_price.$model"
                Type="number"
                min="1"
                max="100000"
                step="1"
                :state="validateState('unit_price')"
                aria-describedby="unit_price-feedback"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </div>
        <div v-if="erreurlist.type_unite" class="error-message">
          <ul v-if="Array.isArray(erreurlist.type_unite)">
            <span v-for="(e, index) in erreurlist.type_unite" :key="index">
              {{ e }}
            </span>
          </ul>
          <span v-else>{{ erreurlist.type_unite }}</span>
        </div>
        <div v-if="erreurlist.quantite" class="error-message">
          <ul v-if="Array.isArray(erreurlist.quantite)">
            <span v-for="(e, index) in erreurlist.quantite" :key="index">
              {{ e }}
            </span>
          </ul>
          <span v-else>{{ erreurlist.quantite }}</span>
        </div>
        <div v-if="erreurlist.unit_price" class="error-message">
          <ul v-if="Array.isArray(erreurlist.unit_price)">
            <span v-for="(e, index) in erreurlist.unit_price" :key="index">
              {{ e }}
            </span>
          </ul>
          <span v-else>{{ erreurlist.unit_price }}</span>
        </div>
        <div
          v-if="
            v$.newforfait.unit_price.$error || v$.newforfait.type_unite.$error
          "
          class="error-message"
        >
          {{ $t("REQUIRED_FIELD") }}
        </div>

        <b-form-group :label="$t('DESC')" label-for="Description">
          <b-form-textarea
            id="desc"
            v-model="newforfait.desc"
            placeholder="Description..."
            rows="3"
            max-rows="6"
          ></b-form-textarea>
          <div v-if="erreurlist.desc" class="error-message">
            <ul v-if="Array.isArray(erreurlist.desc)">
              <span v-for="(e, index) in erreurlist.desc" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.desc }}</span>
          </div>
        </b-form-group>
        <!-- <b-form-checkbox
          id="favoris"
          v-model="newforfait.favoris"
          name="favoris"
          value="true"
          unchecked-value="false"
        >
          {{ $t("FAVORITE") }}
        </b-form-checkbox> -->
      </div>
    </form>
    <template #modal-footer>
      <b-alert variant="warning" show v-if="errorS">
        {{ errorS }}
      </b-alert>
      <div class="double">
        <b-button variant="danger" @click="resetModal()">
          <div class="block-spinner">
            {{ $t("CANCEL") }}
          </div>
        </b-button>
        <b-button variant="success" @click="addPRO" v-if="!getforfaitLoading">
          <div class="block-spinner">
            {{ $t("SAVE") }}
          </div>
        </b-button>
        <b-button variant="success" @click="addPRO" v-else disabled>
          <div class="block-spinner">
            {{ $t("SAVE") }}
            <div v-if="getforfaitLoading" class="loading ml-2">
              <div class="spinner-border" role="status"></div>
            </div>
          </div>
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  maxLength,
  integer,
  numeric,
  minValue,
  maxValue,
} from "@vuelidate/validators";
import errorHandle from "../ui/errorHandle.vue";

export default {
  components: { errorHandle },
  setup() {
    return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
  },
  data() {
    return {
      newforfait: {
        lib: null,
        ref: null,
        desc: null,
        type_unite: "m2",
        quantite: 1,
        unit_price: 1,
        tva: 0,
        favoris: null,
      },
      erreurlist: {
        lib: null,
        ref: null,
        desc: null,
        type_unite: null,
        quantite: null,
        unit_price: null,
        tva: null,
      },
      optionsTVA: [
        { value: 0, text: "0%" },
        { value: 5.5, text: "5.5%" },
        { value: 10, text: "10%" },
        { value: 20, text: "20%" },
      ],
      optionstype: [
        { value: "m2", text: "m2" },
        { value: "ml", text: "ML" },
        { value: "h", text: "h" },
        { value: "forfait", text: this.$t("TypePrestation.Forfait") },
        { value: "unitaire", text: this.$t("unit_unitaire") },
      ],
      errorS: null,
      box: "",
    };
  },
  validations() {
    return {
      newforfait: {
        lib: { required, maxLength: maxLength(100) },
        ref: { maxLength: maxLength(1000) },
        quantite: {
          required,
          integer,
          minValue: minValue(1),
          maxValue: maxValue(1000),
        },
        type_unite: { required },
        unit_price: {
          required,
          numeric,
          minValue: minValue(1),
          maxValue: maxValue(100000),
        },
        tva: { required, numeric },
      },
    };
  },
  methods: {
    ...mapActions(["all_entreprises", "store_forfait"]),

    validateState(name) {
      const { $dirty, $error } = this.v$.newforfait[name];
      return $dirty ? !$error : null;
    },

    async addPRO() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.errorS = null;
      this.erreurlist = {
        lib: null,
        ref: null,
        desc: null,
        type_unite: null,
        quantite: null,
        unit_price: null,
        tva: null,
      };
      await this.store_forfait(this.newforfait)
        .then(() => {
          this.resetModal();
          this.$emit("updateList");
        })
        .catch((err) => {
          if (this.isObject(err)) {
            for (const [key, value] of Object.entries(err)) {
              if (!this.erreurlist[key]) {
                this.erreurlist[key] = value;
              }
            }
          } else {
            this.errorS = err;
          }
        });
    },
    resetModal() {
      this.errorS = null;
      this.$refs["forfaitModal"].hide();
      this.newforfait = {
        lib: null,
        ref: null,
        desc: null,
        type_unite: "m2",
        quantite: 1,
        unit_price: 1,
        tva: 0,
        favoris: null,
      };

      this.$nextTick(() => {
        this.v$.$reset();
      });

      this.erreurlist = {
        lib: null,
        ref: null,
        desc: null,
        type_unite: null,
        quantite: null,
        unit_price: null,
        tva: null,
      };
    },
    isObject(obj) {
      return Object.prototype.toString.call(obj) === "[object Object]";
    },
    close(bv) {
      if (this.$refs["forfaitModal"].isShow) {
        if (bv.trigger == "backdrop") {
          bv.preventDefault();
          this.showMsgBox();
        }
      }
    },

    showMsgBox() {
      this.box = "";
      this.$bvModal
        .msgBoxConfirm(`${this.$t("CLOSE")}`, {
          title: `${this.$t("CONFIRM")}`,
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          cancelVariant: "danger",
          okTitle: `${this.$t("YES")}`,
          cancelTitle: `${this.$t("NO")}`,
          footerClass: "p-2",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) {
            this.resetModal();
          }
        });
    },
  },
  computed: {
    ...mapGetters(["getAllentreprises", "getOnlineUser", "getforfaitLoading"]),
    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    isentrepriseAdmin() {
      return this.getOnlineUser.role == "user.entreprise-admin";
    },
    computedAllentreprises() {
      var list = [
        {
          lib: "TOUTES LES ENTREPRISES",
          id: 1,
        },
      ];
      if (this.getAllentreprises)
        this.getAllentreprises.forEach((element) => {
          if (element.id != 1) list.push(element);
        });
      return list;
    },
  },
};
</script>

<style lang="scss" scoped>
.modalforfait {
  display: flex;
  align-items: center;
  width: 100%;
  .nbunite {
    white-space: nowrap;
  }
}
</style>
